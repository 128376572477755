
import { IonPage, IonCard, IonItem, IonLabel, IonButton, IonInput, IonToast
  , IonCardHeader , IonCardTitle, IonLoading} from '@ionic/vue'
import { logIn, personAdd } from 'ionicons/icons';
import AuthService from '../services/auth.service';
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";


export default {
  name: 'Forgot',
  components: { IonPage,
    IonCard,
    IonItem,
    IonLabel,
    IonButton,
    IonInput,
    IonCardTitle,
    IonCardHeader,
    IonLoading,
    IonToast
  },
  setup() {
    return {
      logIn,
      personAdd,
      $v: useVuelidate()
    };
  },
  data() {
    return {
      email: '',
      loading: false,
      message:'',
      success: null,
      error: null,
      selectedColor: 'warning'
    }
  },
  computed: {

  },
  validations(){
    return{
      email: {required, email}
    }
  },
  methods: {
    requestReset(email){
      this.success = null;
      this.error = null;
      AuthService.requestReset(email)
          .then(() => {
            this.success = true;
            this.error = false;
            this.message ='A recovery email is on its way'
          })
          .catch(error => {
            this.success = false;
            this.error = true;
            this.message ='An error has occured'
          });
    },
    resetEmail(){
      this.email ='';
    }
  }
}

